/***************
メインカラー：#000000
****************/
$def-letter-spc: -0.02em;
$color-midashi: #B66C84;
$color-body: #222222;
$color-inner-link: #FF006C;
$color-accent: #C691B1;
$common-width: 1080px;

#fv {
  width: 100%;

  .fv-wrapper {
    background-image: url("/wp-content/themes/genius/assets/img/sp/bg-header.png");
    background-position: top center;
    background-size: auto 300px;
    background-repeat: no-repeat;
    padding-top: 0px;

    .logo-wrapper {
      width: 100%;
      padding-top: 60px;
      padding-bottom: 50px;
      text-align: center;

      .logo {
        width: 154px;
      }

      .sub-title {
        font-size: 12px;
        margin-top: 20px;
        color: #838383;
      }
    }//.logo-wrapper
    .mask {
      margin-top: 146px;
      width: 100%;
      height: 60px;
      background: rgb(255,255,255);
      background: -moz-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
      background: -webkit-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
      background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
    }
  }//.fv-wrapper
  .fv-wrapper-under {
    padding-top: 10px;

    .line {
      background-color: #E5649F;
      width: 1px;
      height: 50px;
      margin: 0 auto 25px auto;
    }//.line

    .slogan {
      font-size: 18px;
      color: $color-midashi;
      letter-spacing: 0.05em;
      text-align: center;
    }//.slogan

    .slogan-desc {
      width: 100%;
      text-align: center;
      margin: 15px auto 0 auto;
      font-size: 12px;
      padding: 0 35px;
      line-height: 180%;
      color: #707070;

      >a {
        color: #B66C84;
        text-decoration: underline;
        padding: 0 1px;
      }
      .notice {
        color: #4A4A4A;
        font-size: 10px;
        margin-top: 15px;
        line-height: 180%;
      }//.notice

      &.under {
        padding-bottom: 0px;

        img {
          width: 100%;
        }

      }//.under
    }//.slogan-desc
  }//.fv-wrapper-under

  #recent-news {
    width: 100%;
    margin: 40px auto 10px auto;
    .news-wrapper {
      padding: 20px 20px;
      .left {
        color: $color-midashi;
        font-size: 12px;
        text-align: left;
        margin-bottom: 5px;
      }//.left
      .right {
        text-align: left;
        width: 100%;

        .date {
          font-size: 12px;
          color: #5D5D5D;
          padding-right: 5px;

        }//.date
        .title {
          font-size: 12px;
          line-height: 1.4;
          a {
            color: #000;

            &:hover {
              text-decoration: underline;
              color: $color-inner-link;
            }
          }
        }//.title
      }//.right
    }//.news-wrapper
  }//#recent-news


}//#fv



#main-menu {
  margin-top: 20px;

  .main-menu-wrapper {
    padding: 20px;
    margin: 0 auto;
    .menu-box {
      width: 100%;
      margin-bottom: 10px;

      .thumbnail-wrapper {
        width: 100%;
        height: 100px;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        // border: 1px solid #F0F0F0;

        &.about {
          background-image: url("/wp-content/themes/genius/assets/img/sp/banner-about.png");
        }
        &.courses {
          background-image: url("/wp-content/themes/genius/assets/img/sp/banner-courses.png");
        }
        &.contact {
          background-image: url("/wp-content/themes/genius/assets/img/sp/banner-contact.png");
        }

      }//.thumbnail-wrapper

      .title {
        font-size: 16px;
        letter-spacing: 0.05em;
        color: #3E3B39;
        text-align: left;
      }//.title

      .sub-title {
        font-size: 11px;
        color: #464646;
        text-align: left;
      }//.sub-title

    }//.menu-box
  }//.main-menu-wrapper
}//#main-menu






#course {
  .line {
    width: 1px;
    height: 83px;
    background-color: #E5649F;
    margin: -30px auto 30px auto;
  }//.line

  .title {
    text-align: center;
    color: #AB134D;
    font-size: 28px;
    margin-bottom: 7px;
  }

  h2 {
    font-size: 18px;
    color: $color-midashi;
    letter-spacing: 0.25em;
    text-align: center;
    width: 100%;
  }

  .course-outer {
    width: 100%;
  }

  .course-wrapper {
    padding: 20px;

    .course-box {
      width: 100%;
      background-color: #FFF;
      margin-bottom: 20px;

      .wrapper {

        .desc {
          font-size: 11px;
          color: #222222;
          margin-bottom: 20px;
          line-height: 160%;

          a {
            color: #222222;
          }
        }
      }

      h3 {
        text-align: center;
        color: #000000;
        font-size: 15px;
        letter-spacing: 0.08em;
        margin: 15px auto 13px auto;
        font-family: a-otf-midashi-mi-ma31-pr6n,serif;
        font-weight: 600;
        font-style: normal;
      }

      .thumbnail {
        width: 100%;
        height: 240px;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
      }//.thumbnail-wrapper

      .appeal-button {
        width: calc(50% - 6px);
        width: -webkit-calc(50% - 6px);
        float: left;
      }
      .common-button {
        width: calc(50% - 6px);
        width: -webkit-calc(50% - 6px);
        float: right;
      }

    }//.course-box
  }//.course-wrapper
}//#course


#profile {
  padding: 0 20px;

  h2 {
    color: #000000;
    letter-spacing: 0.05em;
    font-size: 15px;
    margin-bottom: 20px;
    text-align: center;
  }

  .title{
    font-size: 24px;
    text-align: center;
    margin-bottom: 5px;
  }

  .about-logo {
    margin: 0 auto;
    text-align: center;
    background-image: url("/wp-content/themes/genius/assets/img/sp/bg-aboutlogo.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 50px 0;

    .left {
      margin-top: 35px;

      img {
        width: 153px;
        display: block;
        margin: 0 auto 50px auto;
      }
    }//.left
    .right {
      padding: 0 20px;
      color: #3B3B3B;
      font-size: 12px;
      line-height: 170%;
    }//.right
  }//.about-logo


  .about-teacher {
    margin: 50px auto 0 auto;

    .left {
      width: 100%;
      color: #363333;
      font-size: 12px;
      line-height: 170%;

      .title {
        margin-bottom: 8px;
      }

      .appeal-button {
        width: 150px;
        margin-top: 30px;
      }
    }//.left
    .right {
      width: 100%;
      text-align: right;

      img {
        width: 157px;
        margin-bottom: -5px;
        margin-top: -50px;
        display: inline;
      }
    }//.right

  }//.about-logo
}//#profile

#news {
  padding: 0 25px;
  .title.font-hoefler {
    text-align: center;
    color: #AB134D;
    font-size: 28px;
    margin-bottom: 7px;
  }
  .line {
    width: 1px;
    height: 64px;
    margin: 0 auto 30px auto;
    background-color: #E8A2C2;
  }//.line
  h2 {
    font-size: 18px;
    color: $color-midashi;
    letter-spacing: 0.3em;
    text-align: center;
    width: 100%;
  }
  .news-wrapper {
    width: 100%;
    margin: 30px auto 20px auto;

    .news-box {
      width: calc((100% - 25px * 1) / 2);
      width: -webkit-calc((100% - 25px * 1) / 2);
      display: inline-block;
      vertical-align: top;
      background-color: #FFF;
      margin-bottom: 20px;

      &:nth-child(2n) {
        margin-left: 19px;
      }

      .wrapper {
        padding: 50px 50px;

        .desc {
          font-size: 13px;
          color: #222222;
          margin-bottom: 30px;
          line-height: 180%;
        }
      }

      .thumbnail {
        width: 100%;
        height: 105px;
        background-image: url("/wp-content/themes/genius/assets/img/common/news-01.png");
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        border: 1px solid #F0F0F0;
      }//.thumbnail-wrapper

      .date {
        color: #5D5D5D;
        font-size: 10px;
        letter-spacing: 0.02em;
      }
      .title {
        font-size: 12px;
        color: #000000;
        margin: 10px 0 10px 0;
        &:hover {
          color: $color-inner-link;
          text-decoration: underline;
        }
        a {
          color: #000;
        }
      }

      .common-button {
        margin-top: 12px;
      }

    }//.news-box

    .operation-area {
      width: 100%;
      margin: 20px auto 0 auto;
    }//.operation-area
  }//.news-wrapper
}//#news


#footer-menu {
  padding-top: 10px;

  .footer-wrapper {

    .menu-box {
      padding: 0 25px;
      margin-bottom: 40px;

      img {
        width: 100%;
      }
      .line {
        width: 1px;
        height: 35px;
        background-color: #E8A2C2;
        margin: -5px auto 0 auto;
      }//.line
      .title {
        font-size: 15px;
        color: $color-midashi;
        letter-spacing: 0.05em;
        text-align: center;
        width: 100%;
        margin: 20px 0 15px 0;

        .en {
          font-size: 25px;
          margin-bottom: 6px;
        }
        a {
          color: $color-midashi;
        }
      }//.title
      .desc {
        font-size: 12px;
        line-height: 180%;
        color: #313131;
        margin-bottom: 15px;
      }//.desc
    }//.menu-box

    .insta-wrapper{
      background-color: #F8F7F7;
      padding: 25px;
      margin-top: 25px;
    }//.insta-wrapper
    #meks_instagram-3 {
      .meks-instagram-widget{
        max-width: 100%!important;
        margin: 0!important;
      }
      img {
      max-width: 100%;
    }
  }
  }//.footer-wrapper
}//#footer-menu
